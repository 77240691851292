<template>
  <v-layout column>
      <CoreHeader title="Blog" />
      <CoreBody>
        <v-sheet :class="isLoading ? 'body-loading' : ''">

        <v-progress-circular
            v-if="isLoading"
            color="secondary"
            indeterminate
            width="2"
            :size="150"/>

        <BlogList v-show="!isLoading" @isLoading="v => isLoading = v" />

        </v-sheet>
      </CoreBody>
  </v-layout>
</template>

<script>
export default {
  name: "blog-list",
  components: {
    CoreBody: () => import('@/components/core/Body'),
    CoreHeader: () => import('@/components/core/Header'),
    BlogList: () => import('@/components/blog/List')
  },
  data: () => ({
    isLoading: true
  })
}
</script>

<style scoped>
.body-loading {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
</style>
